import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Tile from "./Tile";

function HalloweenSlider() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(`./assets/data/halloween-items.json?r=${Date.now()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  }, []);
  return (
    <section id="titles" className="padVer">
      <div className="container-fluid">
        <div className="text-center mb-5">
          <img
            className="mb-2"
            src="/assets/images/spokystreams.png"
            alt="Spooky Streams - Halloween Movies"
          />
          <h2>Halloween Movies</h2>
        </div>
        <div className="titles-slide">{!data ? "" : getSliderItems(data)}</div>
      </div>
    </section>
  );
}

function getSliderItems(data) {
  let settings = {
    dots: false,
    lazyLoad: "ondemand",
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    focusOnSelect: true,
    autoplay: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {data.map((item, key) => {
        return item.nodeId ? <Tile item={item} key={key} /> : "";
      })}
    </Slider>
  );
}
export default HalloweenSlider;
