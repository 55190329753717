import React, { useState, useEffect } from "react";

function BlogTiles() {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    fetch(`./assets/data/blogs.json?r=${Date.now()}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setListData(myJson);
      });
  }, []);
  return (
    <React.Fragment>
      {listData && listData.length > 0 ? getTiles(listData) : ""}
    </React.Fragment>
  );
}
const getTiles = (items) => {
  let heroImage = "./assets/images/blog-bg.webp";
  let heroImageSM = "./assets/images/blog-bg-sm.webp";
  return (
    <section
      id="watchblogtiles"
      className="padVerTab"
      style={{
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container-fluid row col-md-12 d-flex justify-content-center web-view">
        <div className="row text-center">
          <a href="./blogs/" rel="" className="blog-link gtm-lp gtm-blog-link">
            <h1>Blogs</h1>
          </a>
          {/* <h3></h3> */}
          <span className="my-2">
            <a
              href="./blogs/"
              rel=""
              className="blog-link gtm-lp gtm-blog-link"
            >
              Stay Up To Date With Trending Movies and More
            </a>
          </span>
        </div>
        {items.slice(0, 3).map((item, index) => (
          <article className="blog-tiles col-md-3" key={index}>
            <div className="blog-tiles-container">
              <div className="blog-tiles-image col-md-12 col-sm-12">
                <img src={item.image} alt={item.alt_text} />
              </div>
              <div className="blog-tiles-content col-md-12 col-sm-12">
                <div className="blog-tiles-meta">
                  <img src="./assets/images/ico-calendar.png"></img>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="date">{item.date}</span>
                </div>
                <hr />
                <div className="blog-tiles-header">
                  <h2>{item.title}</h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: item.short_description }}
                />
                <div className="d-flex justify-content-start flex-wrap">
                  <p>
                    <img
                      style={{ width: "1.5em", marginBottom: "0em" }}
                      src="./assets/images/ico-timer.svg"
                    ></img>
                  </p>
                  &nbsp;&nbsp;
                  <div
                    style={{ marginTop: "2px" }}
                    dangerouslySetInnerHTML={{ __html: item.reading_time }}
                  />
                </div>
                <a
                  href={`./blogs/${item.id}`}
                  className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </article>
        ))}
        <div className="text-center show-all">
          <a href="./blogs/" rel="" className="blog-link gtm-lp gtm-blog-link">
            <p>
              Go To Blogs
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </p>
          </a>
        </div>
      </div>
      <div className="container-fluid d-flex justify-content-center mobile-view d-none">
        <div className="text-center">
          <a href="./blogs/" rel="" className="blog-link gtm-lp gtm-blog-link">
            <h1>Blogs</h1>
          </a>
          <span className="my-2">
            <a
              href="./blogs/"
              rel=""
              className="blog-link gtm-lp gtm-blog-link"
            >
              Stay Up To Date With Trending Movies and More
            </a>
          </span>
        </div>
        {items.slice(0, 1).map((item, index) => (
          <article className="blog-tiles col" key={index}>
            <div className="blog-tiles-container">
              <div className="blog-tiles-image col-md-12 col-sm-12">
                <img src={item.image} alt={item.alt_text} />
              </div>
              <div className="blog-tiles-content col-md-12 col-sm-12">
                <div className="blog-tiles-meta">
                  <img src="./assets/images/ico-calendar.png"></img>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="date">{item.date}</span>
                </div>
                <hr />
                <div className="blog-tiles-header">
                  <h2>{item.title}</h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: item.short_description }}
                />
                <div className="d-flex justify-content-start flex-wrap">
                  <p>
                    <img
                      style={{ width: "1.5em", marginBottom: "0em" }}
                      src="./assets/images/ico-timer.svg"
                    ></img>
                  </p>
                  &nbsp;&nbsp;
                  <div
                    dangerouslySetInnerHTML={{ __html: item.reading_time }}
                  />
                </div>
                <a
                  href={`./blogs/${item.id}`}
                  className="btn btn-primary read-more-btn gtm-lp gtm-blog-read-more"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </article>
        ))}
        <div className="text-center show-all">
          <a href="./blogs/" rel="" className="blog-link gtm-lp gtm-blog-link">
            <p>
              Go To Blogs
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-double-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"
                />
                <path
                  fillRule="evenodd"
                  d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </p>
          </a>
        </div>
      </div>
    </section>
  );
};

export default BlogTiles;
