import FavouriteGenresInview from "./FavouriteGenresInview";
import NoSubscriptions from "./NoSubscriptions";
import TilesSliderInView from "./TilesSliderInView";
import TrailerInView from "./TrailerInView";
import HalloweenSliderInView from "./HalloweenSliderInView";
import WatchEveryWhereTab from "./WatchEveryWhereTab";
import AppInstall from "./AppInstall";
import Footer from "./Footer";
import FooterInView from "./FooterInView";
import UserConsent from "./UserConsent";
import LoaderAnim from "./LoaderAnim";
import React, { useState } from "react";
import HeroSection from "./HeroSection";
import FAQ from "./FAQ";
import AboutFTI from "./AboutFTI";

import BlogTilesInview from "./BlogTilesInview";

function Home() {
  return (
    <React.Fragment>
      <div className="page">
        <AppInstall></AppInstall>
        <HeroSection></HeroSection>
        <WatchEveryWhereTab></WatchEveryWhereTab>
        <NoSubscriptions></NoSubscriptions>
        <TilesSliderInView></TilesSliderInView>
        <TrailerInView></TrailerInView>
        <HalloweenSliderInView></HalloweenSliderInView>
        <BlogTilesInview></BlogTilesInview>
        <FavouriteGenresInview />
        <AboutFTI></AboutFTI>
        <FAQ></FAQ>
        <FooterInView></FooterInView>
        <span className="m-1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <UserConsent></UserConsent>
      </div>
      <LoaderAnim id="wanim"></LoaderAnim>
    </React.Fragment>
  );
}

const wait = (delay = 0) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const setVisible = (elementOrSelector, visible) => {
  let element =
    typeof elementOrSelector === "string"
      ? document.querySelector(elementOrSelector)
      : elementOrSelector;
  if (element) {
    element.style.display = visible ? "block" : "none";
  }
  /*(typeof elementOrSelector === "string"
    ? document.querySelector(elementOrSelector)
    : elementOrSelector
  ).style.display = visible ? "block" : "none";*/
};

wait(1).then(() => {
  setVisible(".page", false);
  setVisible("#loading", true);
  /*try {
    setVisible(".page", false);
  } catch (error) {}
  try {
    setVisible("#loading", true);
  } catch (error) {}*/
});

document.addEventListener(
  "DOMContentLoaded",
  () => {
    setVisible(".page", true);
    setVisible("#loading", false);
  }
  // wait(2000).then(() => {
  //   setVisible(".page", true);
  //   setVisible("#loading", false);
  // })
);
export default Home;
