import { Link } from "react-router-dom";
function WebTab() {
  let tabWeb = "./assets/images/tab-web.png";
  return (
    <>
      <img className="tabConImg" src={tabWeb} alt="web" />
      {/* <div className="tabConBot">
        <Link to="/titles" className="btn btn-primary" target="_blank">
          Watch Now!
        </Link>
      </div> */}
    </>
  );
}
export default WebTab;
